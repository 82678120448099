import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styles from './authButton.module.css';
import logoutIcon from '../../assets/images/logout.png';

const LogoutButton = () => {
  const { logout  } = useAuth0();

  return <button className={styles.logoutBtn}
                onClick={() => logout({ logoutParams: {returnTo: window.location.origin}})}> 
            <img src= {logoutIcon}/>
            Sign Out
         </button>;
};

export default LogoutButton;