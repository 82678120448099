import React from 'react';
import styles from './loginScreen.module.css';
import LoginButton from '../buttons/loginButton.js';
import CORLLogo from '../../assets/images/corl-logo-reversed.png';

const LoginScreen = () => {
    return (
        <div className={styles.outer}>
            <div className={styles.inner}>
                <div className={styles.innerContent}>
                    <img src={CORLLogo}/>
                    <h1>Welcome</h1>
                    <p>Log in to CORL Technologies to continue to CORL AI POC.</p>
                    <p><LoginButton/></p>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;