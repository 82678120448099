import React, {useState, useEffect, useRef} from 'react';
import styles from './pdfChat.module.css';
import ChatMessage from './chatMessage.js';

const ChatComponent = ({pdfSelected, selectCitation, token}) => {
    const [message, setMessage] = useState('');
    const [responseLoading, setResponseLoading] = useState(false);
    const timeOptions = {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    }
    const promptRef = useRef(null);

    const [messageHistory, setMessageHistory] = useState(() => {
        const messageHistoryState = sessionStorage.getItem('messageHistoryState');
        return messageHistoryState ? JSON.parse(messageHistoryState) : [];
    });

    useEffect(() => {
        sessionStorage.setItem('messageHistoryState', JSON.stringify(messageHistory));
        }, [messageHistory]
    );

    //Auto-scroll to bottom when new message is posted
    useEffect(() => {
        const chatBox = document.getElementById('chatBox');
        chatBox.scrollTop = chatBox.scrollHeight;
    }, [messageHistory]);

    const onChangeOfMessage = (e) => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        const textarea = promptRef.current;

        if (textarea) {
            textarea.style.height = "auto";
            let textHeight = textarea.scrollHeight;
            textarea.style.height = textHeight + "px";
        
            if (textHeight >= 300) {
                textarea.style.overflowY = "auto";
            }
        }
    }, [message]);

    const onKeyDown = (e) => {
        if (e.key === 'Enter')
        {
            if(!e.shiftKey && !responseLoading && message.trim() !== '' && pdfSelected !== null) {
                e.preventDefault();
                appendMessageHistory();
            }
            else if (responseLoading || message.trim() === '' || pdfSelected === null)
            {
                e.preventDefault();
            }
        }
    };

    const appendMessageHistory = async () => {
        //Add user question
        setMessageHistory((messageHistory) => [...messageHistory, {user: 'You', time: new Date().toLocaleString('en-US', timeOptions), body: message, type: styles.sentMessage}]);
        setResponseLoading(true);

        const requestBody = {
            question: message,
            file_name: pdfSelected,
            chat_history: [],
        };

        try {
            setMessage('');

            await fetch('https://x4ncmbens8.execute-api.us-east-1.amazonaws.com/qna', {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),
            }).then(data => (data.json())
            .then(filteredData => {
                setMessageHistory((messageHistory) => [...messageHistory, {user: 'CORL AI', body: filteredData['answer'], citations: filteredData['source_documents'], type: styles.receivedMessage, time: new Date().toLocaleString('en-US', timeOptions)}]);
                setResponseLoading(false);
            }));
        }
        catch (error) {
            console.error('Error sending request: ', error);
        }
    };

    return (
        <div className={styles.inner}>
            <div id='chatBox' className={styles.chatHistory}>
                <ul className={styles.list}>
                    {messageHistory.map((msg, index) => (
                        <ChatMessage key={index} msg={msg} selectCitation={selectCitation}/>
                    ))}
                </ul>
            </div>
            <label className={styles.AITyping}>
                {responseLoading ? 'CORL AI is typing...' : null}
            </label>
            <div className={styles.promptBorder}>
                <textarea
                value={message}
                ref={promptRef}
                className={styles.prompt} 
                onChange={onChangeOfMessage}
                onKeyDown={onKeyDown}
                placeholder='Type Message...'/>
            </div>
        </div>
    );
};

export default ChatComponent;