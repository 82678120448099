import React, { useEffect, useRef, useState } from 'react'
import WebViewer from '@pdftron/webviewer';
import styles from './aprysePdfViewer.module.css';

const AprysePDFViewer = ({docUrl, citation}) => {
    const pdfViewerRef = useRef(null);
    const [viewerInstance, setViewerInstance] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(null);

    useEffect(() => {        
        WebViewer(
            {
                path: '/static/webviewer/lib',
                licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
            },
            pdfViewerRef.current,
        ).then((instance) => {
            instance.UI.disableElements(['toolsHeader', 'ribbons']);
            setViewerInstance(instance);
        });
    }, []);

    const annotateCitation = () => {
        const { Annotations, annotationManager, documentViewer } = viewerInstance.Core; 

        const annotationList = annotationManager.getAnnotationsList();
        annotationManager.deleteAnnotations(annotationList);

        let quadsList = [];
        let dupQuadsList = new Map();

        const highlightQuads = (quads, pageNumber) => {
            const annotation = new Annotations.TextHighlightAnnotation();

            annotation.PageNumber = pageNumber;
            annotation.Quads = quads;
            annotation.StrokeColor = new Annotations.Color(255, 255, 0);

            annotationManager.addAnnotation(annotation);
            annotationManager.redrawAnnotation(annotation);
        }

        const retrieveQuads = async (doc) => { 
            let pageContent = citation['page_content'];

            // If the page contains a table, separate each cell. 
            const searchTerms = pageContent.split("\n");

            const pageNumber = parseInt(citation['metadata']['page']);
            const loadedPageText = await doc.loadPageText(pageNumber);
            const pageText = loadedPageText.replaceAll("\n", " ");

            documentViewer.setCurrentPage(pageNumber);

            for (let term of searchTerms) {
                term = term.trim();
                let startIndex = pageText.indexOf(term, 0);
                let endIndex = startIndex + term.length;

                if (startIndex !== -1 && (startIndex !== endIndex)) {
                    let quads = await doc.getTextPosition(pageNumber, startIndex, endIndex);

                    // Check if a duplicate block of text exists
                    let duplicateFound = false;
                    while (startIndex !== -1) {
                        startIndex = pageText.indexOf(term, endIndex);

                        if (startIndex !== -1) {
                            if (!duplicateFound) {
                                duplicateFound = true;
                            }

                            endIndex = startIndex + term.length;
                            let dupQuads = await doc.getTextPosition(pageNumber, startIndex, endIndex);

                            if (dupQuadsList.has(term)) {
                                let termQuads = dupQuadsList.get(term);
                                termQuads.push(dupQuads);
                                dupQuadsList.set(term, termQuads);
                            }
                            else {
                                dupQuadsList.set(term, [dupQuads]);
                            }
                        }
                        else {
                            if (duplicateFound) {
                                let termQuads = dupQuadsList.get(term);
                                termQuads.push(quads);
                                dupQuadsList.set(term, termQuads);
                            }
                            else {
                                quadsList.push(quads);
                            }
                        }
                    }
                }
            };

            // Find distance between unique text and duplicate text
            if (quadsList.length > 0) {
                let sourceCoords = quadsList[0]

                while (Array.isArray(sourceCoords)) {
                    sourceCoords = sourceCoords[0];
                }
                const sourceX = sourceCoords["x1"];
                const sourceY = sourceCoords["y1"];

                for (let term of dupQuadsList) {
                    let minDistance = {dist: Number.MAX_SAFE_INTEGER, quads: null};
                    for (let dupQuads of term[1]) {
                        let vertCoords = dupQuads[0]

                        while (Array.isArray(sourceCoords)) {
                            vertCoords = vertCoords[0];
                        }
                        const vertX = vertCoords["x1"];
                        const vertY = vertCoords["y1"];

                        let distance = findDistance(sourceX, sourceY, vertX, vertY);
                        if (distance < minDistance["dist"]) {
                            minDistance = {dist: distance, quads: dupQuads};
                        }
                    }
                    // Add the closest block of duplicate text to the list of quads-to-be-highlighted
                    quadsList.push(minDistance["quads"]);
                }
    
                for (let quads of quadsList) {
                    highlightQuads(quads, pageNumber);
                };
            }
        };
        
        const doc = documentViewer.getDocument();

        if (doc !== null) {
            retrieveQuads(doc);
        }
    }

    const onLoadPdf = () => {
        if (docUrl !== currentDoc) {
            viewerInstance.UI.loadDocument(docUrl);
            setCurrentDoc(docUrl);
        }
    }

    const findDistance = (x1, y1, x2, y2) => {
        const x3 = x2 - x1;
        const y3 = y2 - y1;
        return Math.sqrt(x3 * x3 + y3 * y3);
    }

    if (pdfViewerRef !== null && viewerInstance !== null) {
        onLoadPdf();
    }

    if (citation !== null && pdfViewerRef !== null && viewerInstance !== null) {
        annotateCitation();
    }

    return (
        <div ref={pdfViewerRef} className={styles.aprysePDFViewer}/>
    )
}

export default AprysePDFViewer