import React, {useState, useEffect} from 'react';
import styles from './chatMessage.module.css';

const ChatMessage = ({msg, selectCitation}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDropdown = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleCitation = (citation) => {
        selectCitation(citation);
    }

    function sortByPage(a, b) {
        return a.metadata.page - b.metadata.page;
    }

    function hasCitations() {
        if (msg.citations) {
            if (msg.citations.length > 0) {
                return true;
            }
        }
        return false;
    }

    return (
        <li className={msg.type}>
            <strong>{msg.user}</strong>&nbsp;&nbsp;<small>{msg.time}</small><br/>
            <p className={styles.messageBody}>{msg.body}</p>
            {hasCitations() ? (
                <div>
                    <div className={styles.citationsDropdown}>
                        <button className={styles.citationsDropdownLabel} onClick={() => toggleDropdown()}>
                            {isExpanded ? 'Hide citations' : 'Show citations'}
                        </button>
                    </div>
                    <ul className={`${styles.citationsList} ${isExpanded ? `${styles.citationsListExpanded}` : ''}`} >
                        {msg.citations.sort(sortByPage).map((citation) => (
                            <li key={msg.citations.indexOf(citation)} className={styles.citation} onClick={() => toggleCitation(citation)}>
                                pg. {citation['metadata']['page']}) <i>"{citation['page_content'].length > 100 ? citation['page_content'].substring(0, 100) + '...' : citation['page_content']}"</i>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                null
            )}
        </li>
    );
};

export default ChatMessage;