import React from "react";
import styles from './uploadPdfButton.module.css';

const UploadPDFButton = ({onUpload, token}) => {

  const retrieveSignedUrl = async (e) => {
    const uploadedFile = e.target.files[0];
    const fileName = uploadedFile.name;
    const requestBody = JSON.stringify({
      file_name: fileName
    });

    try {
      await fetch('https://x4ncmbens8.execute-api.us-east-1.amazonaws.com/upload_pdf', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
          },
          body: requestBody,
      }).then(data => (data.json())
      .then(filteredData => {
        uploadPdf(filteredData['url'], uploadedFile, fileName)
      }));
    }
    catch (error) {
      console.error('Error sending request: ', error);
    }
  }

  const uploadPdf = async (url, uploadedFile, fileName) => {

    const response = await fetch(url, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: uploadedFile
      });

    if (!response.ok) {
      console.log('Error uploading file');
    }    
    else {
      onUpload(fileName);
      loadPdf(fileName);
    }
  }

  const loadPdf = async (fileName) => {
    const requestBody = JSON.stringify({
      file_names: [fileName]
    });

    const response = await fetch('https://x4ncmbens8.execute-api.us-east-1.amazonaws.com/load_pdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: requestBody,
    });

    if (!response.ok) {
      console.log('Error loading file');
    }
  }

  return <label className={styles.uploadBtn}>
            <input type='file' accept='.pdf' onChange={retrieveSignedUrl}/>
            Upload File
         </label>;
};

export default UploadPDFButton;