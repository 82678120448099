import React from 'react';
import styles from './home.module.css';

const Home = ({token}) => {
    return (
        <div className={styles.outer}>
            <h1>CORL AI POC</h1>
        </div>
    );
};

export default Home;