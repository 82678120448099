import React, {useState, useEffect} from 'react';
import styles from './pdfViewer.module.css';
import AprysePDFViewer from '../aprysePdfViewer/aprysePdfViewer.js';
import ChatComponent from '../pdfChat/pdfChat.js';
import FileUpload from './fileUpload.js';

const PDFViewer = ({token}) => {
    const [selectedPdfDoc, setSelectedPdfDoc] = useState(null);
    const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const [selectedCitation, setSelectedCitation] = useState(null);

    const openModal = (modalType) => {
        setActiveModal(modalType);
    };

    const closeModal = () => {
        setActiveModal(null);
    };

    useEffect(() => {
        handlePdfUpload();
    });

    const handlePdfUpload = () => {
        const retrievedUrl = sessionStorage.getItem('selectedPdfUrl');
        const retrievedDoc = sessionStorage.getItem('selectedPdfDoc');

        if (retrievedUrl) {
            setSelectedPdfUrl(retrievedUrl);
            setSelectedPdfDoc(retrievedDoc);
        }
    };

    const selectCitation = (citation) => {
        if (citation) {
            setSelectedCitation(citation);
        }
    }

    return (
        <div className={styles.outer}>
            <div className={styles.contentBox}>
                <div className={styles.spacer}/>
                <div className={styles.chatBox}>
                    <ChatComponent pdfSelected={selectedPdfDoc ? selectedPdfDoc : null} selectCitation={selectCitation} token={token}/>
                </div>
                <div className={styles.miniSpacer}/>
            </div>
            <div className={styles.contentBox}>
                <button className={styles.headerButton} onClick={() => openModal('fileUpload')}>
                    Select File
                </button>
                <div className={styles.pdfBox}>
                    {selectedPdfUrl ? (
                        <AprysePDFViewer docUrl={selectedPdfUrl} citation={selectedCitation}/>
                    ) : (
                        <div className={styles.pdfNotSelected}>
                            <div className={styles.uploadPdf}>
                                <p>Please select a document to view...</p>
                                <button onClick={() => openModal('fileUpload')}>
                                    Select File
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.miniSpacer}/>
                {activeModal && <FileUpload onClose={closeModal} onUpload={handlePdfUpload} token={token}/>}
            </div>
        </div>
    );
};

export default PDFViewer;