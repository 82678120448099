import React, {useState, useEffect} from 'react';
import styles from './nav.module.css';
import NavButton from './navButton.js';
import ParentNavButton from './parentNavButton.js';
import LogoutButton from '../buttons/logoutButton';
import CORLLogo from '../../assets/images/corl-logo-reversed.png';

function NavigationPane() {
    const [isExpanded, setIsExpanded] = useState(() => {
        const paneState = localStorage.getItem('paneState');
        return paneState ? JSON.parse(paneState) : false;
    });
    
    const [selectedItem, setSelectedItem] = useState(() => {
        const selectedItemState = sessionStorage.getItem('selectedItemState');
        return selectedItemState ? JSON.parse(selectedItemState) : null;
    });

    useEffect(() => {
        localStorage.setItem('paneState', JSON.stringify(isExpanded));
        sessionStorage.setItem('selectedItemState', JSON.stringify(selectedItem));
        }, [isExpanded, selectedItem]
    );

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleSelectedOption = (id) => {
        setSelectedItem(id);
    };

    return (
        <div className={`${styles.navPane} ${isExpanded ? `${styles.navPaneExpanded}` : ''}`}>
            <div className={styles.miniSpacer}></div>
            <div className={`${styles.header} ${isExpanded ? `${styles.headerExpanded}` : ''} `}>
                <img src={CORLLogo}/>
            </div>
            <div className={`${styles.navPaneDetails} ${isExpanded ? `${styles.navPaneDetailsExpanded}` : ''}`}>
                <ul className={`${styles.detailsColumn} ${isExpanded ? `${styles.detailsColumnExpanded}` : ''}`}>
                    <li><NavButton label='Home' path= '/' isSelected={selectedItem === 0} onClick={() => toggleSelectedOption(0)}/></li>
                    <li><ParentNavButton label='Audit Tools' dropdownOptions= {
                        [{label: 'PDF Analyzer', path: '/pdfViewer', isChild: true, isSelected: selectedItem === 1, onClick: () => toggleSelectedOption(1)}]}/></li>
                </ul>

                <div className={styles.arrowColumn}>
                    <div className={styles.arrowButton} onClick={toggleSidebar}>
                        <div className={styles.arrow}>{isExpanded ? '<' : '>'}</div>
                        <div className={styles.arrow}>{isExpanded ? '<' : '>'}</div>
                        <div className={styles.arrow}>{isExpanded ? '<' : '>'}</div>
                    </div>
                </div>
            </div>
            <div className={`${styles.footer} ${isExpanded ? `${styles.footerExpanded}` : ''} `}>
                <div className={`${styles.footerContent} ${isExpanded ? `${styles.footerContentExpanded}` : ''}`}>
                    <LogoutButton/>
                </div>
            </div>
            <div className={styles.miniSpacer}></div>
        </div>
    );
}

export default NavigationPane;