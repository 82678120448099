import React from 'react';
import styles from './navButton.module.css';
import {Link} from 'react-router-dom';

const NavButton = ({label, path, isChild, isSelected, onClick}) => {
    return (
        <Link to={path}>
            <div onClick={onClick} className={`${styles.navButton} ${isChild ? `${styles.navButtonChild}` : ''} ${isSelected ? `${styles.navButtonSelected}` : ''}`}>{label}</div>
        </Link>
    );
}

export default NavButton;