import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styles from './authButton.module.css';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button className={styles.loginBtn}
                onClick={() => loginWithRedirect()}>
            Sign In
         </button>;
};

export default LoginButton;