import React, {useState, useEffect} from 'react';
import styles from './fileUpload.module.css';
import UploadPDFButton from '../buttons/uploadPdfButton.js';

const FileUpload = ({onClose, onUpload, token}) => {

    const [pdfList, setPdfList] = useState([]);

    const handlePdfUpload = () => {
        onUpload();
        onClose();
    };

    const selectPdf = async (selectedItem) => {
        try {
            const requestBody = {
                file_name: selectedItem,
            };

            await fetch('https://x4ncmbens8.execute-api.us-east-1.amazonaws.com/download_pdf', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(requestBody),
            }).then(data => (data.json())
            .then(filteredData => {
                sessionStorage.setItem('selectedPdfUrl', filteredData['url']);
                sessionStorage.setItem('selectedPdfDoc', selectedItem);
            }));
        }
        catch (error) {
            console.error('Error sending request: ', error);
        }

        handlePdfUpload();
    }

    useEffect (() => {
        const retrievePdfList = async () => {
            try {
                await fetch('https://x4ncmbens8.execute-api.us-east-1.amazonaws.com/list_pdfs', {
                    method: 'GET',
                    headers: {
                        'Authorization': token,
                    },
                    credentials: 'include'
                }).then(data => (data.json())
                .then(filteredData => {
                    setPdfList(filteredData['pdfs']);
                }));
            }
            catch (error) {
                console.error('Error sending request: ', error);
            }
        };

        retrievePdfList();
    }, []);

    return (
        <div className={styles.outer} onClick={onClose}>
            <div className={styles.inner} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}> 
                    <p>Select a document</p>
                    <UploadPDFButton onUpload={selectPdf} token={token}/>
                </div>
                <div className={styles.selectBox}>
                    {pdfList.map((pdf) => (
                        <div key={pdfList.indexOf(pdf)} className={styles.pdfItem} onClick={() => selectPdf(pdf)}>
                            {pdf}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FileUpload;