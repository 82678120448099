import { useState, useEffect } from 'react';
import './App.css';
import LoginScreen from './components/screens/loginScreen.js';
import { useAuth0 } from "@auth0/auth0-react";
import NavigationPane from './components/nav/nav.js';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PDFViewer from './components/screens/pdfViewer.js';
import Home from './components/screens/home.js';
import FileUpload from './components/screens/fileUpload.js';
import CORLLogo from './assets/images/corl-logo-loading.png';

function App() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [ token, setToken ] = useState();

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = `${process.env.REACT_APP_AUTH_ZERO_DOMAIN}`;
  
      try {
        const accessToken =  await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`
        });
        setToken(accessToken);
      } catch (e) {
        console.log(e.message);
      }

    };
    getUserMetadata();
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return ( 
      <div className='loadingScreen'>
        <img src={CORLLogo} className="appLogo"/>
      </div>
    );
  };

  return (
    <div>
        {isAuthenticated
          ? <div className='appBody'>
              <Router>
                <NavigationPane/>
                <Routes>
                  <Route path = '/' element={<Home token={token}/>}/>
                  <Route path = 'pdfViewer' element={<PDFViewer token={token}/>}/>
                </Routes>
              </Router>
            </div>
          : <LoginScreen/>
        }
    </div>
  );
}
export default App;