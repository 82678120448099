import React, {useState, useEffect} from 'react';
import styles from './parentNavButton.module.css';
import NavButton from './navButton';

const ParentNavButton = ({label, dropdownOptions}) => {
    const [isExpanded, setIsExpanded] = useState(() => {
        const paneState = localStorage.getItem('paneState');
        return paneState ? JSON.parse(paneState) : false;
    });

    useEffect(() => {
        localStorage.setItem('paneState', JSON.stringify(isExpanded));
        }, [isExpanded]
    );

    const handleClick = () => {
        if (dropdownOptions) {
            setIsExpanded(!isExpanded);
        }
    };

    return (
        <div className={styles.parentOption}>
            <div className={styles.parentOptionLabel} onClick = {handleClick}>{label}</div>
            {isExpanded && (
                <ul className={styles.dropdownMenu}>
                    {dropdownOptions.map((option) => (
                        <NavButton key={dropdownOptions.indexOf(option)} path={option.path} label={option.label} isChild={option.isChild} isSelected={option.isSelected} onClick={option.onClick}/>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default ParentNavButton;